<template lang="pug">
  .p-1
    b-card.flat-card.mb-2
      b-row.p-0.m-0
        b-col.p-0.mr-2(xs="6")
          .d-flex.flex-column
            span.text-muted.ml-2(style="font-size: .8rem;") Стоимость от
            b-form-input.rounded-input(size="sm" type="number" v-model="minPrice")
        b-col.p-0(xs="6")
          .d-flex.flex-column
            span.text-muted.ml-2(style="font-size: .8rem;") до
            b-form-input.rounded-input(size="sm" type="number" v-model="maxPrice")
      .d-flex.mt-3
        .w-100.flat-more-btn.p-1.text-center.mr-2(style="font-size: .8rem; cursor: pointer;" @click="filterEstates") Применить
        .w-100.flat-more-btn.p-1.text-center(style="font-size: .8rem; cursor: pointer;" @click="getEstates") Сбросить
      //- .d-flex.justify-content-center(style="font-size: .8rem;")
        span.text-muted.mr-1 Стоимость от 
        span.font-weight-bold.mr-1 {{ sliderMin.toLocaleString('ru') + ' ₽' }} 
        span.text-muted.mr-1 до 
        span.font-weight-bold {{ sliderMax.toLocaleString('ru') + ' ₽' }}
      //- .range-slider
        b-form-input(type="range" min="500000" max="20000000" step="100000" v-model="sliderMin" @change="filterEstates")
        b-form-input(type="range" min="500000" max="20000000" step="100000" v-model="sliderMax" @change="filterEstates")
    .mt-3.d-flex.flex-column.align-items-center.justify-content-center(v-if="loading")
      b-spinner(type="grow" variant="success")
    div(v-else)
      flat-card(v-for="(flat, i) in flats" :key="i" type="buy" :flat="flat" v-if="flats.length > 0")
      .text-center(v-else): span(style="font-size: .8rem;") К сожалению таких квартир нет 🥲
</template>

<script>
import FlatCard from '@/components/FlatCard';

export default {
  components: {FlatCard},
  data() {
    return {
      loading: true,
      allFlats: [],
      flats: [],
      // minAngle: 500000,
      // maxAngle: 2000000,
      minPrice: null,
      maxPrice: null
    }
  },
  // computed: {
  //   sliderMin: {
  //     get() {
  //       let val = parseInt(this.minAngle);
  //       return val;
  //     },
  //     set(val) {
  //       val = parseInt(val);
  //       if (val > this.maxAngle) this.maxAngle = val;
  //       this.minAngle = val;
  //     }
  //   },
  //   sliderMax: {
  //     get() {
  //       let val = parseInt(this.maxAngle);
  //       return val;
  //     },
  //     set(val) {
  //       val = parseInt(val);
  //       if (val < this.minAngle) this.minAngle = val;
  //       this.maxAngle = val;
  //     }
  //   }
  // },
  methods: {
    hideKb() {
      let field = document.createElement('input');
      
      field.setAttribute('type', 'text');
      field.setAttribute('hidden', true);
      document.body.appendChild(field);
      setTimeout(() => {
        field.focus();
        setTimeout(() => {
          field.setAttribute('style', 'display:none;');
        }, 50);
      }, 50);
    },
    async getEstates() {
      this.hideKb();
      this.loading = true;
      
      let {data} = await this.$http.get(`/api/flats?type=second&rooms=${this.$route.params.rooms}&order_by=price&order_dir=asc`);
      
      this.allFlats = data.list;
      this.flats = data.list;
      this.minPrice = Math.min(...this.allFlats.map(x => x.price));
      this.maxPrice = Math.max(...this.allFlats.map(x => x.price));
      this.loading = false;
    },
    async filterEstates() {
      this.hideKb();
      this.loading = true;
      
      let {data} = await this.$http.get(`/api/flats?type=second&rooms=${this.$route.params.rooms}&price_from=${this.minPrice}&price_to=${this.maxPrice}&order_by=price&order_dir=asc`);
      
      this.flats = data.list;
      this.loading = false;
    },
  },
  mounted() {
    this.getEstates();
    this.$nextTick(() => {
      this.$store.state.tg.BackButton.hide();
      this.$store.state.tg.MainButton.hide();
      this.$store.state.tg.expand();
    })
  }
}
</script>

<style scoped>
.range-slider {
  width: 100%;
  position: relative;
  height: 2.5rem;
}

.range-slider input[type=range] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type=range]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  /* border: 1px solid green; */
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #98ee99;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

</style>