<template lang="pug">
  .p-1

    .mt-3.d-flex.flex-column.align-items-center.justify-content-center(v-if="loading")
      b-spinner(type="grow" variant="success")
    div(v-else)
      //- .flat-back-fab.d-flex.flex-column.align-items-center.justify-content-center(@click="$router.back()")
        font-awesome-icon(:icon="['fas', 'arrow-left']")
      //- .flat-send-fab.d-flex.flex-column.align-items-center.justify-content-center(@click="$router.back()")
        font-awesome-icon(:icon="['fas', 'paper-plane']")
      b-card.mb-2.flat-card
        .d-flex.flex-column
          //- .flat-price.d-flex.flex-column.align-items-center.justify-content-center.py-1.px-2
            span.font-weight-bold {{ Number(flat.price).toLocaleString('ru') + ' ₽' }}
          //- span.flat-watermark PRO.ДОМ
          carousel(v-if="flat.photos" :nav="false" :items="1" :margin="5")
            .d-flex.flex-column(v-for="(photo, i) in flat.photos" :key="i")
              b-img.flat-photo(:src="photo")
              b-img.flat-watermark(src="/static/img/favicon.png" style="width: 100px; height: auto;")
      
      b-card.flat-card.mb-2
        .d-flex
          .d-flex.flex-column.mr-2(style="font-size: .8rem;")
            
            .d-flex.align-items-center.mb-1
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'money-bill']")
              span.font-weight-bold(style="font-size: 1rem;" v-text="$route.params.type == 'buy' ? `${Number(flat.price).toLocaleString('ru')} ₽` : `${Number(flat.price).toLocaleString('ru')} ₽/месяц`")
            
            .d-flex.align-items-center.mb-1
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'home']")
              span {{ `${flat.address.city_district ? flat.address.city_district.name + ' г.' : flat.address.city.name}, ${flat.address.street.name}, ${flat.address.house}` }}
            
            .d-flex.mb-1
              .d-flex.align-items-center.mr-2
                font-awesome-icon.mr-1.text-muted(:icon="['fas', 'bars']")
                span Этаж: {{ flat.floor + '/' + flat.floors }}
              .d-flex.align-items-center.mr-2
                font-awesome-icon.mr-1.text-muted(:icon="['fas', 'vector-square']")
                span {{ flat.total_area + 'м²' }}
              .d-flex.align-items-center
                font-awesome-icon.mr-1.text-muted(:icon="['fas', 'bed']")
                span Комнаты: {{ flat.rooms }}
            .d-flex
              .d-flex.align-items-center
                font-awesome-icon.mr-1.text-muted(:icon="['fas', 'clock']")
                span {{ $moment(flat.updated_at).format('LL') }}
      
      b-card.flat-card.mb-2(style="text-align: justify;")
        span(style="font-size: .8rem;") {{ flat.description }}
      
      b-card.flat-card.mb-3(footer-tag="footer")
        b-img.flat-photo(:src="agent.photos[0]" v-if="agent.photos")
        b-img.flat-photo(src="/static/img/placeholders/placeholder.png" v-else)
        <template #footer>
          .d-flex
            .d-flex.flex-column.mr-2(style="font-size: .8rem;")
              span(style="font-size: 1rem;") {{ agent.first_name + ' ' + agent.last_name }}
              span.text-muted.mb-2 {{ agent.position }}
              .d-flex.flex-column
                .d-flex.align-items-center.mr-2.mb-1
                  font-awesome-icon.mr-2.text-muted(:icon="['fas', 'phone-alt']")
                  a(:href="'tel:+' + agent.phone") {{ '+' + agent.phone }}
                .d-flex.align-items-center.mr-2
                  font-awesome-icon.mr-2.text-muted(:icon="['fas', 'envelope']")
                  a(:href="'mailto:' + agent.email") {{ agent.email }}
        </template>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  components: {carousel},
  data() {
    return {
      loading: true,
      flat: {},
      agent: {}
    }
  },
  methods: {
    async getFlat() {
      this.loading = true;
      
      let {data} = await this.$http.get(`/api/flat?type=${this.$route.params.type}&id=${this.$route.params.id}`);
      
      this.flat = data.object;
      this.agent = data.agent;
      this.$store.state.tg.MainButton.text = 'Отправить в чат';
      this.$store.state.tg.MainButton.color = '#98ee99';
      this.$store.state.tg.MainButton.onClick(async () => {
        this.$store.state.tg.MainButton.showProgress();
        await this.$http.post(`/api/send-flat/${this.$route.params.type}/${this.$store.state.tg.initDataUnsafe.user.id}`, {flat: this.flat, agent: this.agent});
        this.$store.state.tg.showPopup({title: 'Объект отправлен в чат', message: 'Данный объект появился у вас в чате, так вы можете делать подборки квартир'});
        this.$store.state.tg.MainButton.hideProgress()
        this.$store.state.tg.MainButton.hide();
      });
      this.$store.state.tg.MainButton.show();
      this.loading = false;
    }
  },
  mounted() {
    this.getFlat();
    this.$nextTick(() => {
      this.$store.state.tg.BackButton.onClick(() => {
        this.$router.back();
        this.$store.state.tg.BackButton.hide();
        this.$store.state.tg.MainButton.hide();
      });
      this.$store.state.tg.BackButton.show();
    })
  }
}
</script>