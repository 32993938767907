import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { API_CONFIG } from '../app-config';

Vue.use(Vuex);

const $http = axios.create(API_CONFIG);
const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {},
    theme: 'light',
    tg: {}
  },
  getters: {
    
  },
  mutations: {
    LOGIN(state, status) {
      state.isLoggedIn = status;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_THEME(state, scheme) {
      state.theme = scheme;
    },
    SET_TG(state, tg) {
      state.tg = tg;
    }
  },
  actions: {
    async getUser ({ state, commit }) {
      let telegram = window.Telegram.WebApp;
      let {data} = await $http.get(`/api/users/data?id=${telegram.initDataUnsafe.user.id}`);
      commit('SET_USER', data);
    },
  }
});

export default store;