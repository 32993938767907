<template lang="pug">
  .p-1
    b-card.flat-card.mb-2
      b-row.p-0.m-0
        b-col.p-0.mr-2(xs="6")
          .d-flex.flex-column
            span.text-muted.ml-2(style="font-size: .8rem;") Стоимость от
            b-form-input.rounded-input(size="sm" type="number" v-model="minPrice")
        b-col.p-0(xs="6")
          .d-flex.flex-column
            span.text-muted.ml-2(style="font-size: .8rem;") до
            b-form-input.rounded-input(size="sm" type="number" v-model="maxPrice")
      .d-flex.mt-3
        .w-100.flat-more-btn.p-1.text-center.mr-2(style="font-size: .8rem; cursor: pointer;" @click="filterEstates") Применить
        .w-100.flat-more-btn.p-1.text-center(style="font-size: .8rem; cursor: pointer;" @click="getEstates") Сбросить
    .mt-3.d-flex.flex-column.align-items-center.justify-content-center(v-if="loading")
      b-spinner(type="grow" variant="success")
    div(v-else)
      flat-card(v-for="(flat, i) in flats" :key="i" type="rent" :flat="flat" v-if="flats.length > 0")
      .text-center(v-else): span К сожалению таких квартир нет 🥲
</template>

<script>
import FlatCard from '@/components/FlatCard';

export default {
  components: {FlatCard},
  data() {
    return {
      loading: true,
      allFlats: [],
      flats: [],
      minPrice: null,
      maxPrice: null
    }
  },
  methods: {
    hideKb() {
      let field = document.createElement('input');
      
      field.setAttribute('type', 'text');
      field.setAttribute('hidden', true);
      document.body.appendChild(field);
      setTimeout(() => {
        field.focus();
        setTimeout(() => {
          field.setAttribute('style', 'display:none;');
        }, 50);
      }, 50);
    },
    async getEstates() {
      this.hideKb();
      this.loading = true;
      
      let {data} = await this.$http.get(`/api/flats?type=rent&rooms_for_rent=${this.$route.params.rooms}&order_by=price&order_dir=asc`);
      
      this.allFlats = data.list;
      this.flats = data.list;

      const min = this.allFlats.reduce((prev, curr) => curr.price < prev.price ? curr : prev);
      const max = this.allFlats.reduce((prev, curr) => curr.price > prev.price ? curr : prev);

      this.minPrice = min.price;
      this.maxPrice = max.price;
      this.loading = false;
    },
    async filterEstates() {
      this.hideKb();
      this.loading = true;
      
      let {data} = await this.$http.get(`/api/flats?type=rent&rooms_for_rent=${this.$route.params.rooms}&price_from=${this.minPrice}&price_to=${this.maxPrice}&order_by=price&order_dir=asc`);
      
      this.flats = data.list;
      this.loading = false;
    },
  },
  mounted() {
    this.getEstates();
    this.$nextTick(() => {
      this.$store.state.tg.BackButton.hide();
      this.$store.state.tg.MainButton.hide();
    })
  }
}
</script>

<style scoped>
.range-slider {
  width: 100%;
  position: relative;
  height: 2.5rem;
}

.range-slider input[type=range] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type=range]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  /* border: 1px solid green; */
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #98ee99;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

</style>