import Vue from 'vue';
import Router from 'vue-router';
import Buy from '@/components/Buy';
import Rent from '@/components/Rent';
import Flat from '@/components/Flat';
import Odobrenie from '@/components/Odobrenie';
import Calc from '@/components/Calc';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return {x: 0, y: 0};
  },
  routes: [
    {
      path: '/buy/:rooms',
      name: 'buy',
      component: Buy
    },
    {
      path: '/rent/:rooms',
      name: 'rent',
      component: Rent
    },
    {
      path: '/flat/:type/:id',
      name: 'flat',
      component: Flat
    },
    {
      path: '/odobrenie',
      name: 'odobrenie',
      component: Odobrenie
    },
    {
      path: '/calc',
      name: 'calc',
      component: Calc
    }
  ]
});

export default router;