<template lang="pug">
  b-card.mb-2.flat-card(footer-tag="footer")
    .d-flex.flex-column
      carousel(v-if="flat.photos" :nav="false" :items="1" :margin="5")
        .d-flex.flex-column(v-for="(photo, i) in flat.photos" :key="i")
          b-img.flat-photo(:src="photo")
          b-img.flat-watermark(src="/static/img/favicon.png" style="width: 100px; height: auto;")
    <template #footer>
      .d-flex
        .d-flex.flex-column.mr-2(style="font-size: .8rem;")
          .d-flex.align-items-center.mb-1
            font-awesome-icon.mr-1.text-muted(:icon="['fas', 'money-bill']")
            span.font-weight-bold(style="font-size: 1rem;" v-text="type == 'buy' ? `${Number(flat.price).toLocaleString('ru')} ₽` : `${Number(flat.price).toLocaleString('ru')} ₽/месяц`")
          .d-flex.align-items-center.mb-1
            font-awesome-icon.mr-1.text-muted(:icon="['fas', 'home']")
            span {{ `${flat.address.city_district ? flat.address.city_district.name + ' г.' : flat.address.city.name}, ${flat.address.street.name}, ${flat.address.house}` }}
          .d-flex.mb-1
            .d-flex.align-items-center.mr-2
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'bars']")
              span Этаж: {{ flat.floor + '/' + flat.floors }}
            .d-flex.align-items-center.mr-2
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'vector-square']")
              span {{ flat.total_area + 'м²' }}
            .d-flex.align-items-center
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'bed']")
              span Комнаты: {{ flat.rooms }}
          .d-flex
            .d-flex.align-items-center
              font-awesome-icon.mr-1.text-muted(:icon="['fas', 'clock']")
              span {{ $moment(flat.updated_at).format('LL') }}
        .d-flex.flex-column.ml-auto
          router-link.flat-more-btn(:to="`/flat/${type}/${flat.id}`")
            .h-100.d-flex.flex-column.align-items-center.justify-content-center
              font-awesome-icon(:icon="['fas', 'arrow-right']")
          .flat-more-btn.mt-auto(@click="sendToChat(flat)" style="cursor: pointer;" v-if="!sending")
            .h-100.d-flex.flex-column.align-items-center.justify-content-center
              font-awesome-icon(:icon="['fas', 'paper-plane']")
          .flat-more-btn.mt-auto(v-else)
            .h-100.d-flex.flex-column.align-items-center.justify-content-center
              b-spinner(type="grow" small)
    </template>
</template>

<script>
import carousel from 'vue-owl-carousel';
export default {
  components: {carousel},
  props: ['flat', 'type'],
  data() {
    return {
      sending: false
    }
  },
  methods: {
    async sendToChat(flat) {
      this.sending = true;
      await this.$http.post(`/api/send-flat/${this.$props.type}/${this.$store.state.tg.initDataUnsafe.user.id}`, {flat});
      this.sending = false;
    }
  }
}
</script>