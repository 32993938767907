<template lang="pug">
  b-card.flat-card(style="font-size: .8rem;")
    .d-flex.flex-column
      .d-flex.mb-2
        span.mr-1.text-muted Хочу квартиру за 
        span.font-weight-bold {{ divide(total_price) + ' ₽' }}
      b-form-input.mb-2(type="range" v-model="total_price" min="10000" max="20000000" step="100000" @change="checkPrice")
      .d-flex.mb-2
        span.mr-1.text-muted У меня есть ПВ в размере 
        span.font-weight-bold {{ divide(initial_fee) + ' ₽' }}
      b-form-input.mb-2(type="range" v-model="initial_fee" min="10000" :max="total_price" step="10000")
      .d-flex.mb-2
        span.mr-1.text-muted На срок
        span.font-weight-bold {{ loan_time + ' лет' }}
      b-form-input.mb-2(type="range" v-model="loan_time" min="1" max="40" step="1")
      .d-flex.mb-2
        span.mr-1.text-muted Под процент
        span.font-weight-bold {{ percents + '%' }}
      b-form-input.mb-3(type="range" v-model="percents" min="1" max="20" step="0.1")
      
      div.mb-3(style="font-size: 1rem;")
        .d-flex
          span.mr-1.text-muted Ипотека: 
          span.font-weight-bold {{ total_ipoteka.toLocaleString('ru') + ' ₽' }}
        .d-flex
          span.mr-1.text-muted Платеж в месяц:
          span.font-weight-bold {{ annual_fee.toLocaleString('ru') + ' ₽' }}
      
      span.text-muted.text-center(style="font-size: .6rem;") Расчет является приблизительным, за точными данными обратитесь к нашим специалистам или к сотрудникам банка
</template>

<script>
export default {
  data() {
    return {
      total_price: 1000000,
      initial_fee: 150000,
      loan_time: 10,
      percents: 2
    }
  },
  computed: {
    loan_time_month () {
      return this.loan_time * 12;
    },
    total_ipoteka () {
      return this.total_price - this.initial_fee;
    },
    annual_fee () {
      return Math.floor(this.total_ipoteka * ( ( (this.percents / 100) * ( (1 + (this.percents / 100)) ** this.loan_time_month ) ) / ( ( (1 + (this.percents / 100)) ** this.loan_time_month ) - 1 ) ));
    }
  },
  methods: {
    checkPrice() {
      if (this.total_price < this.initial_fee) this.initial_fee = this.total_price;
    },
    divide(num) {
      let int = String(Math.trunc(num));
      let space = 0;
      let number = '';
      
      if (int.length <= 3) return int;
      for (let i = int.length - 1; i >= 0; i--) {
        if (space == 3) {
          number = ' ' + number;
          space = 0;
        }
        number = int.charAt(i) + number;
        space++;
      }
      return number;
    }
  }
}
</script>