require('moment/locale/ru');

export default {
  methods: {
    /**
     * Asynchronious forEach
     * @param {*} arr Iterable array
     * @param {*} cb Callback
     */
    async $asyncForEach (arr, cb) {
      for (let i = 0; i < arr.length; i++) {
        await cb(arr[i], i, arr);
      }
    },

    /**
     * Popup toast
     * @param {string} type Toast type
     * @param {string} title Toast message
     * @param {string} text Toast aditional message
     * @param {number} showConfirmButton show/hide confirm button, default = false
     * @param {number} timer Toast duration, default 3000
     */
    $popToast (type, title, text, showConfirmButton=false, timer=3000) {
      this.$swal({
        toast: true,
        position: 'top',
        showConfirmButton, timer, type, title, text
      });
    },

    /**
     * Split array by chunks
     * @param {array} array Array to split
     * @param {number} size Size of chunks
     * @return {array} Multidimensional array
     */
    $splitChunks (array, size) {
      let chunks = [];
      for (let i = 0; i < Math.ceil(array.length / +size); i++) {
        chunks.push(array.slice(+size * i, +size * (i + 1)));
      }
      return chunks;
    },

    /**
     * Shuffle array
     * @param {array} array Array to shuffle
     */
    $shuffleArray (array=[]) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    $debounce (callback, wait, immediate = false) {
      let timeout = null 
      
      return function() {
        const callNow = immediate && !timeout
        const next = () => callback.apply(this, arguments)
        
        clearTimeout(timeout)
        timeout = setTimeout(next, wait)
    
        if (callNow) {
          next()
        }
      }
    }
  }
};