<template lang="pug">
  b-card.flat-card(style="font-size: .8rem; text-align: justify;")
    .d-flex.flex-column
      p.mb-2(@click="hideKb") {{`Для того чтобы проверить одобрение ипотеки нам понадобится не много информации`}}
      b-form-input.my-2.rounded-input(v-model="data.fio" size="sm" placeholder="ФИО")
      b-form-input.my-2.rounded-input(v-model="data.citizenship" size="sm" placeholder="Гражданство" style="z-index: 9999;")
      b-form-input.my-2.rounded-input(v-model="data.birthday" size="sm" type="text" placeholder="Дата рождения" v-mask="'99/99/9999'")
      b-form-input.my-2.rounded-input(v-model="data.tel" size="sm" type="text" placeholder="Телефон" v-mask="'+7 (999) 999-99-99'")
</template>

<script>
export default {
  data() {
    return {
      data: {
        fio: '',
        citizenship: '',
        birthday: '',
        tel: ''
      }
    }
  },
  methods: {
    hideKb() {
      let field = document.createElement('input');
      
      field.setAttribute('type', 'text');
      document.body.appendChild(field);
      setTimeout(() => {
        field.focus();
        setTimeout(() => {
          field.setAttribute('style', 'display:none;');
        }, 50);
      }, 50);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.state.tg.MainButton.text = 'Отправить';
      this.$store.state.tg.MainButton.color = '#98ee99';
      this.$store.state.tg.MainButton.onClick(async () => {
        this.$store.state.tg.MainButton.showProgress();
        await this.$http.post(`/api/lid-from-bot`, this.data);
        this.$store.state.tg.showPopup({title: 'Информация отправлена', message: `Мы свяжемся с тобой в ближайшее время`});
        this.$store.state.tg.MainButton.hideProgress();
        this.$store.state.tg.close();
      });
      this.$store.state.tg.MainButton.show();
    })
  }
}
</script>