<template lang="pug">
#app.h-100
  .h-100.pt-2.px-2: router-view
</template>

<script>
export default {
  mounted() {
    let docHead = document.querySelector("head");
    let theme = document.createElement("link");
    
    this.$store.commit('SET_TG', window.Telegram.WebApp);
    theme.setAttribute("rel", "stylesheet");
    theme.setAttribute("href", this.$store.state.tg.colorScheme == 'dark' ? "/static/css/dark.css" : "/static/css/light.css");
    docHead.append(theme);
  },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
</style>