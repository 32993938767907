// Old browsers support
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';

// Components
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import mixins from './mixins';
import Paginate from 'vuejs-paginate';
import VueMeta from 'vue-meta';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';

// Fontawesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {} from '@fortawesome/free-regular-svg-icons';
import {
  faHome, faBars, faVectorSquare, 
  faBed, faArrowRight, faPhoneAlt,
  faEnvelope, faClock, faArrowLeft,
  faPaperPlane, faHeart, faMoneyBill
} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
library.add(
  faHome, faBars, faVectorSquare, 
  faBed, faArrowRight, faPhoneAlt,
  faEnvelope, faClock, faArrowLeft,
  faPaperPlane, faHeart, faMoneyBill
);

// App config
import { IMG_URL, API_CONFIG } from './app-config';

const moment = require('moment');
require('moment/locale/ru');
const VueInputMask = require('vue-inputmask').default;

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.$http = axios.create(API_CONFIG);
Vue.prototype.IMG_URL = IMG_URL;

Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'), { moment });
Vue.use(VueInputMask);
Vue.component('paginate', Paginate);
// Mixins
Vue.mixin(mixins);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
});